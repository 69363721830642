<template>
  <div class="container">
    <div class="iv2-accordion">
      <div class="iv2-accordion__list">
        <div
          v-for="(item, index) in customize.accordion"
          :key="item.id"
          :class="{ open: activeMenu[index] }"
          class="iv2-accordion__item"
        >
          <div
            @click="activeBtn(index)"
            class="d-flex"
            style="justify-content: space-between; border-bottom: 1px solid"
          >
            <h3 class="iv2-accordion__title">{{ item.title }}</h3>
            <div class="iv2-accordion__arrow position-relative"></div>
          </div>
          <transition
            name="accordion"
            @before-enter="beforeEnter"
            @enter="enter"
            @before-leave="beforeLeave"
            @leave="leave"
          >
            <div
              class="iv2-accordion__body"
              v-show="show[index]"
              v-html="item.body"
            ></div>
          </transition>
        </div>
      </div>
      <div class="row clearfix">
        <div class="column full" style="margin: 0 auto">
          <div style="text-align: center">
            <span>
              <a
                :href="customize.btn_link"
                target="_brank"
                rel="noopener"
                class="theme__main-btn is-btn is-btn-ghost2 is-upper"
                style="
                  border: 0;
                  letter-spacing: 2px;
                  max-width: 100%;
                  width: 300px;
                  padding-top: 15px;
                  padding-bottom: 15px;
                  margin: 5px;
                "
                >{{ customize.btn_text }}</a
              >
            </span>
          </div>
        </div>
        <div class="column full">
          <div class="spacer height-120"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["customize"],

  data() {
    return {
      activeMenu: {},
      show: {},
    };
  },

  methods: {
    // activeList: function(e) {
    // 	e.currentTarget.parentNode.classList.toggle("open");
    // },

    activeBtn: function (key) {
      this.$set(this.show, key, !this.show[key]);
      this.$set(this.activeMenu, key, !this.activeMenu[key]);
    },

    beforeEnter: function (el) {
      el.style.height = "0";
    },
    enter: function (el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave: function (el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave: function (el) {
      el.style.height = "0";
    },
  },
};
</script>

<style lang="scss">
.iv2-accordion {
  width: 100%;
  padding: 0 30px;

  .iv2-accordion__list {
    margin-bottom: 80px;

    .iv2-accordion__item {
      cursor: pointer;
      margin-top: 20px;
      margin-bottom: 15px;

      .iv2-accordion__title {
        height: 40px;
        line-height: 40px;
        font-size: 1.5rem;
        width: 90%;
        position: relative;
      }

      .iv2-accordion__arrow {
        width: 27px;
        height: 45px;
        margin-right: 5px;

        &::before {
          content: "";
          width: 20px;
          height: 20px;
          border-left: 1px solid;
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translateX(50%) translateY(-50%) rotate(-45deg);
          transition: 0.2s;
        }

        &::after {
          content: "";
          width: 20px;
          height: 20px;
          border-bottom: 1px solid;
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translateX(50%) translateY(-50%) rotate(-45deg);
          transition: 0.2s;
        }
      }

      .iv2-accordion__body {
        overflow: hidden;
        cursor: default;
        padding: 20px 30px;
        -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
        transition: 150ms ease-out;
      }

      &.open {
        .iv2-accordion__arrow {
          &::before {
            right: 0;
          }

          &::after {
            right: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .iv2-accordion {
    padding: 0;

    .iv2-accordion__list {
      .iv2-accordion__item {
        .iv2-accordion__title {
          font-size: 1.2rem;
        }
      }
    }
  }
}
</style>
