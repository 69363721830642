<template>
  <section class="iv2-page-header">
    <div class="overflow-hidden">
      <div class="iv2-page-header__title text-center position-relative">
        <h1
          class="iv2-page-header__main-title theme-b__page-header-title h4 m-0"
        >
          {{ page_title }}
        </h1>
        <p
          class="iv2-page-header__sub-title theme-b__page-header-sub-title m-0 mt-1"
        >
          {{ page_subtitle }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["customize"],

  data() {
    return {
      page_title: "ご注文方法",
      page_subtitle: "guide",
    };
  },
};
</script>

<style lang="scss">
.iv2-page-header__title {
  .iv2-page-header__main-title {
    font-size: 1.5rem;
    border-bottom: 1px solid;
    padding: 30px 5px 5px;
    font-weight: bold;
    display: inline-block;
  }

  .iv2-page-header__sub-title {
    font-size: 12px;
    font-weight: 600;
  }
}

/* PC */
@media (min-width: 1200px) {
  .iv2-page-header__title {
    padding: 100px 50px;
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-page-header__title {
    padding: 60px 30px;
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-page-header__title {
    padding: 50px 20px;
    .iv2-page-header__main-title {
      font-size: 1.3rem;
    }
  }
}
</style>
