<template>
  <div>
    <article class="iv2-article" v-if="pageActive">
      <Error404 v-if="customize.status == 0" />

      <div v-else-if="customize.status == 1">
        <CustomizeTitle :customize="customize" />
        <CustomizeAccordion :customize="customize" />
      </div>
    </article>
    <div v-if="loading">
      <BaseLoading />
    </div>
  </div>
</template>

<script>
import CustomizeTitle from "./components/CustomizeTitle.vue";
import CustomizeAccordion from "./components/CustomizeAccordion.vue";
import BaseLoading from "@/components/items/BaseLoading.vue";
import Error404 from "@/components/systems/Page404.vue";
import Meta from "@/mixins/MetaSettable";

export default {
  components: {
    CustomizeTitle,
    CustomizeAccordion,
    BaseLoading,
    Error404,
  },

  mixins: [Meta],

  props: {
    animation: String,
  },

  data() {
    return {
      customize: {
        title: "イラストケーキ",
        subtitle: "Illustration Cake",
        accordion: [
          {
            id: 1,
            title: "ご注文について",
            body: '<div class="row clearfix"><div class="column full"><p style="line-height: 1.5;"><span style="font-size: 14px;">お申し込みは、お届けの2ヶ月前～10日前までお受けできます。製作数に限りがありますので、お早めにお願いいたします。期日が迫っている場合でも可能な場合もございます。お約束はできませんが、お気軽にお問合わせ下さい。<br></span></p><p style="margin: 0px; padding: 0px; line-height: 1.5;"><span style="font-size: 14px;">メールでのご注文</span></p><ol style="padding: 0px 20px; margin: 0px;"><li style="line-height: 1.5;"><span style="font-size: 14px;">ご注文メールを頂いた当日または翌営業日に、確認のお電話またはメールをさせていただきます。<br></span></li><li style="line-height: 1.5;"><span style="font-size: 14px;">詳細を打ち合わせ後に、ご注文内容確認のメールを当店より送信いたします。<br></span></li><li style="line-height: 1.5;"><span style="font-size: 14px;">必要事項を全て記載し返信をお願いいたします。<br></span></li><li style="line-height: 1.5;"><span style="font-size: 14px;">再度お電話またはメールにて内容をご確認いただき、ご注文を確定させていただきます。<br></span></li><li style="line-height: 1.5;"><span style="font-size: 14px;">ご注文確定のメールを差し上げますので、必ずご確認下さい。<br></span></li></ol><p style="margin: 0px; padding: 0px; line-height: 1.5;"><span style="font-size: 14px;">※確定メールが届かない場合は、ご注文が成立しておりません。メールが届かない場合は、お問い合わせ下さいますようお願いいたします。</span></p><p style="line-height: 1.5;"><span class="size-14">お電話でのご注文<br>受付時間10：00～18：00<br>※火曜日定休<br>電話番号：022-244-2166</span><br></p></div></div>',
          },
          {
            id: 2,
            title: "お申込みサイズについて",
            body: '<div class="row clearfix"><div class="column full"><p style="line-height: 1.5;"><span class="size-14">立体ケーキ　6号サイズ～\n<br>立体ケーキは最小サイズが6号です。<br>※キャラクターの難易度が高い場合やイラスト数の追加には加算料金が発生いたします。</span><br></p><p></p><p style="line-height: 1.5;"><span class="size-14">イラストケーキ　5号サイズ～<br>※5号サイズはイラスト1つまでです。<br>小さいイラストや細かいイラストに関しては描ききれない場合がございますのでご了承ください。</span><br></p></div></div>',
          },
          {
            id: 3,
            title: "商品の価格について",
            body: '<div class="row clearfix"><div class="column full"><p style="line-height: 1.5;"><span class="size-14">5号サイズ￥4,190～（イラストのみ）<br>6号サイズ￥5,086～（立体最小サイズ）<br>7号サイズ￥6,415～<br>※梱包料・税込料金です。<br>8・9・10号サイズは別途お見積り致します。</span><br></p></div></div>',
          },
          {
            id: 4,
            title: "お支払いについて",
            body: '<div class="row clearfix"><div class="column full"><p style="line-height: 1.5;"><span class="size-14">お申し込みが確定してから3日以内にお振込みをお願いいたします。<br>振込手数料はお客様のご負担でお願いいたします。\n\n<br>振込確認後ケーキの制作に取り掛かります。</span><br></p><p style="line-height: 1.5;"><span class="size-14">[振込口座]<br>住信SBIネット銀行<br>レモン支店<br>支店番号　104<br>及川　士　（オイカワ　ツカサ）<br>※&nbsp;住信SBIネット銀行<br>振込・入出金手数料が無料です。</span><br></p><p style="line-height: 1.5;"><span class="size-14">郵便局<br>ぱるる口座<br>18140-1487131<br>オイカワ　ツカサ</span><br></p></div></div>',
          },
          {
            id: 5,
            title: "配送について",
            body: '<div class="row clearfix"><div class="column full"><p style="line-height: 1.5;"><span class="size-14">クール便を利用して全国にお届けいたします。送料はお客様でご負担ください。店頭受取をご希望のお客様には送料がかかりません。&nbsp;<br><br>時間帯指定について<br>午前中、14時～16時、16時～18時、18時～20時、19時～21時の中から、商品受取に、ご都合の良い時間帯をお選び下さい。<br><br>荷物追跡機能について<br>商品を発送する際、メールにて荷物追跡に必要なURLと番号をお知らせいたします。&nbsp;</span><br></p></div></div>',
          },
          {
            id: 6,
            title: "送料について",
            body: '<div class="row clearfix"><div class="column full"><p style="line-height: 1.5;"><span class="size-14">合計代金<br>5号サイズのイラストケーキを関東に発送した場合の料金は…<br>4,190円（商品代金）＋924円（送料）<br>＋220円（クール代金）=5,334円となります。<br><br>代引きご希望の場合は、＋330円（代引き料金）がかかります。</span><br></p></div></div>',
          },
          {
            id: 7,
            title: "キャンセル・返品について",
            body: '<div class="row clearfix"><div class="column full"><p style="line-height: 1.5;"><span class="size-14">当店がケーキの製作を開始してからのキャンセルはできません。ご了承下さい。<br>通常は返品不可とさせていただきます。</span><br></p></div></div>',
          },
        ],
        btn_link: "http://charapate.com/contact_gr/quotation.php",
        btn_text: "ご注文画面へ",
        status: 1,
      },
      loading: true,
      pageActive: false,
    };
  },

  created() {
    this.loading = false;
    this.pageActive = true;
  },
};
</script>

<style lang="scss"></style>
